import { createApp } from 'vue'

import App from './App.vue'
import './tablestyle.css';
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';

//import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

//App.use(BootstrapVue);
//App.use(BootstrapVueIcons); (bootstrap not current supporting, just using styles)

import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

Amplify.configure(aws_exports);

Amplify.configure({
  Auth: {
    identityPoolId: 'us-east-1:5d439ce6-e882-4c97-8668-15a2695f6d0f',
    region: 'us-east-1'
  },
  Interactions: {
    bots: {
      "AccountBalance": {
        "name": "AccountBalance",
        "alias": "$LATEST",
        "region": "us-east-1",
      },
    }
  }
});

applyPolyfills().then(() => {
  defineCustomElements(window);
});

const app = createApp(App);
app.config.isCustomElement = tag => tag.startsWith('amplify-');
app.mount("#app");


/*Amplify.configure({
  Auth: {
    identityPoolId: 'us-east-1:5d439ce6-e882-4c97-8668-15a2695f6d0f',
    region: 'us-east-1'
  },
  Interactions: {
    bots: {
      "AccountBalance": {
        "name": "AccountBalance",
        "alias": "$LATEST",
        "region": "us-east-1",
      },
    }
  }
});*/
