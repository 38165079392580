
<template>
  <div id="app">
    


  <!--<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">Navbar</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">donothing</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">donothing</a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
        </li>
      </ul>
    </div>
  </div>
</nav> -->

<div class="card text-center">
 <!-- <div class="card-header">
    Featured
  </div> -->
  <div class="card-body">
    <h5 class="card-title">Account Balance Chat Bot & automated Contact Centre</h5>
    <p class="card-text"> The Contact Centre number is 020 3991 7080 and you can talk to me (the bot) for your balance using the same Journey and authenitcation process</p>
    <p> Pick a customer number below and update the mobile number (and other fields in the simple form at the bottom) to your own in a UK format with leading 0 or enter 123123 when prompted to bypass SMS authentication (last 6 digits and OTP steps) </p>
    <p> If you leave feedback (either in the bot or verbally) we'll detect the sentiment and record key phrases. If your feedback contains Negative sentiment we'll customise the message apologising and creating a note in CRM </p>
    <p> Keep an eye on the Sentiment and Customer tables shown for live updates </p>
    <a href="https://dq066flni1o2t.cloudfront.net/" target="_blank" class="btn btn-primary">Account Balance Chat</a>
  </div>
</div>

    <amplify-chatbot
      bot-title="Account Balance Bot"
      bot-name="AccountBalance"
      welcome-message="Hello, how can I help you? Just ask for 'Account Balance'"
      voice-enabled="false"/> 
      
<p></p>
<!--<span class="badge bg-info text-dark">Info</span>-->
<!--<span class="badge bg-warning text-dark">Warning</span>-->
<button class="btn btn-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Awaiting sentiment analysis
</button> delivered by GraphQL subscriptions (AWS AppSync resovling to DynamoDB NoSQL) via AWS Lambda, Analysis AWS Comprehend
 <h5> </h5>
<div class="table-responsive-sm">
  <table class="table table-responsive-sm">
    <caption>Customer sentiment recorded from interactions</caption>
     <thead class="table-dark">
     <tr><td>Customer ID</td><td>Sentiment</td><td> Key Phrases </td> <td> Sentiment Score </td></tr> </thead>
      <tbody>
     <tr v-for="item in sentiments" :key="item.id" table table-striped table-bordered table-hover table-condensed>
       <td>{{ item.id }}</td>
       <td>{{ item.sentiment }}</td>
       <td>{{ item.keyphrases }} </td>      
       <td>{{ item.sentimentfull }} </td>
      </tr>
     </tbody>
    </table>
</div>
  <p></p>
<button class="btn btn-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
 Awaiting customer interaction
</button>  delivered by GraphQL subscriptions (AWS AppSync resolving to DynamoDB NoSQL) via AWS Lambda, SMS via AWS SNS
 <h5></h5>
   <div class="table-condensed table-responsive">
        <table class="table table-responsive-sm">
          <caption>Customer authenitcation activity and details</caption>
           <thead class="table-dark">
     <tr><td>Customer ID</td><td>Name</td><td>Account Number</td><td>Balance </td><td>Mobile</td><td>OTP </td><td>OTP Status</td><td>OTP issued </td></tr>
           </thead>
     <tbody>
     <tr v-for="item in customers" :key="item.id"><td> {{item.id}}</td><td>{{ item.name }}</td><td>{{ item.accountnumber }}</td><td>{{ item.accountbalance }} </td><td>{{ item.mobile }}</td><td>{{ item.otp}} </td><td>{{ item.otpstatus }}</td><td>{{ item.OTPdatetime }}</td></tr>
     </tbody>
    </table>
   </div>

 <div class="card-footer text-muted">
<ul> <b>100% Serverless and SaaS solution hosted on AWS </b> (in this case us-east-1, N. Virginia). Responsive single page design with Vue 3.0.</ul>
<ul> There are <b>three channel's</b> which are integrated, a ChatBot, Contact Centre Bot and Web App (to show you whats is happening).</ul>
<ul> You can speak to the bot on the number above via AWS Connect and the attached Contact Centre. </ul>
<ul> You could easily use a custom app (i.e. within an existing app or web page) </ul>
<ul> The AWS Lex Chat Bot utilises AWS Lambda (Serverless function) to validate all input and take action. Loads and saves the customer, checks that the phone number matches the account, sends an SMS message with a OTP, retrieves the Balance from DynamoDB and finally conducts sentiment analysis on the feedback entered recording the result (and publishing it for all apps to see). </ul>
<ul> <b>Notice updates to the customer record and sentiment records displayed (clearly for internal use or to update other systems) will (subject to browser support) update dynamically</b> as the data is updated. This is an example of the VUE application subscribing to updates managed by AWS AppSync (GraphQL resolving to DynamoDB NoSQL database). The App doesn't poll for updates rather this App (and millions  of other can subscribe to updates), this tech can handle many concurrent connections </ul>
<ul> <b>A few prices</b> - The SMS message is exponentially the most expensive part at $0.04 per message. AWS AppSync costs $4.00 per Million requests,  Lex (chat bot), $75 per 100K, DynamoDB, $1.50 and 30c per Millom write and read requests, 30c per GB of data stored. AWS Lambda costs would cost circa 1c per hour of compute time (but charges by the millisecond). </ul>
</div>

<p></p>
 <h5>Change details, all fields required</h5> (if you input your mobile number reset otherwise you might get unwanted sms messages)
 <p></p>
    <table>
     <tr><td>Field</td><td>New Value</td></tr>
     <tr> <td></td></tr>
    <tr><td>Customer Number </td><td><input type="text" v-model="id" placeholder=""></td></tr>
    <tr><td>Account Name </td><td><input type="text" v-model="name" placeholder=""></td></tr>
    <tr><td>Account Balance </td><td><input type="text" v-model="accountbalance" placeholder=""></td></tr>
    <tr><td>Mobile Number </td><td><input type="text" v-model="mobile" placeholder=""> </td></tr>
    </table>
    <p></p>
    <p> 
    <button v-on:click="updateCustomer">Update Customer</button>
    </p>


  </div>
</template>
<script>
import { API } from 'aws-amplify';
import { createCustomer } from './graphql/mutations';
import { updateCustomer } from './graphql/mutations';
import { listCustomers } from './graphql/queries';
import { listSentiments } from './graphql/queries';
import { onCreateCustomer } from './graphql/subscriptions';
import { onCreateSentiment } from './graphql/subscriptions';
import { onUpdateCustomer } from './graphql/subscriptions';
import { onUpdateSentiment} from './graphql/subscriptions';

export default {
  name: 'app',
  metaInfo: {
      title: 'Your Digital Change'
    },
  async created() {
    this.getCustomers();
    this.getSentiments();
    this.subscribe();
    this.subscribeCreateSentiments();
    this.subscribeCustomerUpdates();
    this.subscribeSentimentUpdates();
  },

  chatbotConfig: {
    bot: "AccountBalance",
    clearComplete: false,
   },

  data() {
    return {
      accountnumber: null, 
      accountbalance: null, 
      customernumber: "", 
      dynamicscustomerid: "", 
      email: "", 
      id: "", 
      mobile: "", 
      name: "", 
      otp: null, 
      otpdatetime: null, 
      otpstatus: "",
      customers: [],
      sentiments: [],
      customertoupdate: null,
      sentimenttoupdate: null
    }
  },
  
  methods: {
    isMatchID: function(element) {
      return element.id === this.customertoupdate.id;
    },
    isSentimentMatchID: function(element) {
      return element.id === this.sentimenttoupdate.id;
    },
    subscribeCustomerUpdates() {
       API.graphql({ query: onUpdateCustomer })
        .subscribe({
          next: (eventData) => {
            //console.log(JSON.stringify(eventData));
            //Mutation that was called - which this listens to -  has to contain needed fields i.e. createCustomer(vars) {return vars}
           console.log("subscribe called: " + eventData.value.data.onUpdateCustomer);
           this.customertoupdate = eventData.value.data.onUpdateCustomer;
            // find record and update if we have something and then if we find it
            //console.log("customer ot update " + this.customertoupdate);
            if (this.customertoupdate!= null) {
              let index = this.customers.findIndex(this.isMatchID);
              //console.log("index of element to replace " + index);
                if (index!= -1){
                  this.customers.splice(index,1,this.customertoupdate);
                }    
            }
          }
        });
    }, 
    subscribeSentimentUpdates() {
       API.graphql({ query: onUpdateSentiment })
        .subscribe({
          next: (eventData) => {
            //console.log(JSON.stringify(eventData));
            //Mutation that was called - which this listens to -  has to contain needed fields i.e. createCustomer(vars) {return vars}
           console.log("Sentiment subscribe called: " + eventData.value.data.onUpdateSentiment);
           this.sentimenttoupdate = eventData.value.data.onUpdateSentiment;
            // find record and update if we have something and then if we find it
           // console.log("sentiment to update " + this.sentimenttoupdate);
            //console.log("Sentiment ID: " + this.sentimenttoupdate.id);
            //console.log("Sentiment: " + this.sentimenttoupdate.sentiment);
            //console.log("Sentimentful : " + this.sentimenttoupdate.sentimentfull);
            if (this.sentimenttoupdate!= null) {
              let index = this.sentiments.findIndex(this.isSentimentMatchID);
              //console.log("index of element to replace " + index);
                if (index!= -1){
                  this.sentiments.splice(index,1,this.sentimenttoupdate);
                }    
            }
          }
        });
    }, 

    subscribe() {
       API.graphql({ query: onCreateCustomer })
        .subscribe({
          next: (eventData) => {
            //console.log(JSON.stringify(eventData));
            //Mutation that was called - which this listens to -  has to contain needed fields i.e. createCustomer(vars) {return vars}
            let customer = eventData.value.data.onCreateCustomer;
            if (this.customers.some(item => item.id === customer.id)) return; // remove duplications
            this.customers = [...this.customers, customer];
          }
        });
    },
   subscribeCreateSentiments() {
       API.graphql({ query: onCreateSentiment })
        .subscribe({
          next: (eventData) => {
            console.log(JSON.stringify(eventData));
            //Mutation that was called - which this listens to -  has to contain needed fields i.e. createCustomer(vars) {return vars}
            let sentiment = eventData.value.data.onCreateSentiment;
            //if (this.sentiments.some(item => item.id === sentiment.id)) return; // remove duplications
            this.sentiments = [...this.sentiments, sentiment];
          }
        });
    },
    async getCustomers() {
      const customers = await API.graphql({
        query: listCustomers
      });
      this.customers = customers.data.listCustomers.items;
    },
    async getSentiments() {
      const sentiments = await API.graphql({
        query: listSentiments
      });
      this.sentiments = sentiments.data.listSentiments.items;
    },
    async createCustomer() {
      const {accountnumber, accountbalance,customernumber,dynamicscustomerid,email,id,mobile,name,otp,otpdatetime,otpstatus} = this;
      //console.log("ID");
      if (!id) return;
      const customer = {accountnumber,accountbalance,customernumber,dynamicscustomerid,email,id,mobile,name,otp,otpdatetime,otpstatus};
      await API.graphql({query: createCustomer, variables: {input: customer},});
      
      this.id = '';
    },
    async updateCustomer() {
      const {accountbalance,id,mobile,name} = this;
      //console.log("ID");
      if (!id) return;
      const customer = {accountbalance,id,mobile,name};
      await API.graphql({query: updateCustomer, variables: {input: customer},});
      
      //this.id = '';
    },
  }
};
</script>