/* eslint-disable */
// this is an auto generated file. This will be overwritten
// removed below from subscription
//createdAt
//updatedAt
//
export const onCreateSentiment = /* GraphQL */ `
  subscription OnCreateSentiment {
    onCreateSentiment {
      id
      sentiment
      sentimentfull
      keyphrases
      entities
    }
  }
`;
export const onUpdateSentiment = /* GraphQL */ `
  subscription OnUpdateSentiment {
    onUpdateSentiment {
      id
      sentiment
      sentimentfull
      keyphrases
      entities
    }
  }
`;
export const onDeleteSentiment = /* GraphQL */ `
  subscription OnDeleteSentiment {
    onDeleteSentiment {
      id
      sentiment
      sentimentfull
      keyphrases
      entities
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer {
    onCreateCustomer {
      id
      dynamicscustomerid
      name
      mobile
      otp
      email
      accountnumber
      accountbalance
      otpstatus
      otpdatetime
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer {
    onUpdateCustomer {
      id
      dynamicscustomerid
      name
      mobile
      otp
      email
      accountnumber
      accountbalance
      otpstatus
      otpdatetime
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer {
    onDeleteCustomer {
      id
      dynamicscustomerid
      name
      mobile
      otp
      email
      accountnumber
      accountbalance
      otpstatus
      otpdatetime
    }
  }
`;
