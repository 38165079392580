/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createSentiment = /* GraphQL */ `
  mutation CreateSentiment(
    $input: CreateSentimentInput!
    $condition: ModelSentimentConditionInput
  ) {
    createSentiment(input: $input, condition: $condition) {
      id
      sentiment
      sentimentfull
      keyphrases
      entities
      createdAt
      updatedAt
    }
  }
`;
export const updateSentiment = /* GraphQL */ `
  mutation UpdateSentiment(
    $input: UpdateSentimentInput!
    $condition: ModelSentimentConditionInput
  ) {
    updateSentiment(input: $input, condition: $condition) {
      id
      sentiment
      sentimentfull
      keyphrases
      entities
      createdAt
      updatedAt
    }
  }
`;
export const deleteSentiment = /* GraphQL */ `
  mutation DeleteSentiment(
    $input: DeleteSentimentInput!
    $condition: ModelSentimentConditionInput
  ) {
    deleteSentiment(input: $input, condition: $condition) {
      id
      sentiment
      sentimentfull
      keyphrases
      entities
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      dynamicscustomerid
      name
      mobile
      otp
      email
      accountnumber
      accountbalance
      otpstatus
      otpdatetime
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      dynamicscustomerid
      name
      mobile
      otp
      email
      accountnumber
      accountbalance
      otpstatus
      otpdatetime
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      dynamicscustomerid
      name
      mobile
      otp
      email
      accountnumber
      accountbalance
      otpstatus
      otpdatetime
      createdAt
      updatedAt
    }
  }
`;
