/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSentiment = /* GraphQL */ `
  query GetSentiment($id: ID!) {
    getSentiment(id: $id) {
      id
      sentiment
      sentimentfull
      keyphrases
      entities
    }
  }
`;
export const listSentiments = /* GraphQL */ `
  query ListSentiments(
    $filter: ModelSentimentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSentiments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sentiment
        sentimentfull
        keyphrases
        entities
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      dynamicscustomerid
      name
      mobile
      otp
      email
      accountnumber
      accountbalance
      otpstatus
      otpdatetime
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        dynamicscustomerid
        name
        mobile
        otp
        email
        accountnumber
        accountbalance
        otpstatus
        otpdatetime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
